var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.visible,"closeOnEscape":true,"closable":true,"modal":true,"containerStyle":{ width: '70vw' }},on:{"show":function($event){return _vm.onShow()},"hide":function($event){return _vm.onHide()},"update:visible":function($event){return _vm.fechar()}}},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","value":_vm.historico,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":[5, 10, 25],"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} consignações","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Histórico")]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" Nenhum histórico encontrado. ")]},proxy:true}])},[_c('Column',{attrs:{"header":"Cadastrado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.cadastradoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Alterado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.alteradoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Usuário"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.usuario ? data.usuario : '-')+" ")]}}])}),_c('Column',{attrs:{"header":"Porcentagem Margem Empréstimo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.porcentagemMargem6 ? data.porcentagemMargem6 : '-')+" ")]}}])}),_c('Column',{attrs:{"header":"Porcentagem Margem Cartão"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.porcentagemMargem4 ? data.porcentagemMargem4 : '-')+" ")]}}])}),_c('Column',{attrs:{"header":"Porcentagem Margem Bens Duráveis"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.porcentagemMargem3 ? data.porcentagemMargem3 : '-')+" ")]}}])}),_c('Column',{attrs:{"header":"Porcentagem Margem Adiantamento"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.porcentagemMargem5 ? data.porcentagemMargem5 : '-')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }