<template>
  <Dialog
    :visible="visible"
    :closeOnEscape="true"
    :closable="true"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <DataTable
      class="p-datatable-sm"
      :paginator="true"
      :rows="5"
      stripedRows
      :value="historico"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
      responsiveLayout="scroll">
      <template #header>Histórico</template>
      <template #empty> Nenhum histórico encontrado. </template>
      <Column header="Cadastrado Em">
        <template #body="{ data }">
          {{ data.cadastradoEm | formatarData }}
        </template>
      </Column>
      <Column header="Alterado Em">
        <template #body="{ data }">
          {{ data.alteradoEm | formatarData }}
        </template>
      </Column>
      <Column header="Usuário">
        <template #body="{ data }">
          {{ data.usuario ? data.usuario : '-' }}
        </template>
      </Column>
      <Column header="Porcentagem Margem Empréstimo">
        <template #body="{ data }">
          {{ data.porcentagemMargem6 ? data.porcentagemMargem6 : '-' }}
        </template>
      </Column>
      <Column header="Porcentagem Margem Cartão">
        <template #body="{ data }">
          {{ data.porcentagemMargem4 ? data.porcentagemMargem4 : '-' }}
        </template>
      </Column>
      <Column header="Porcentagem Margem Bens Duráveis">
        <template #body="{ data }">
          {{ data.porcentagemMargem3 ? data.porcentagemMargem3 : '-' }}
        </template>
      </Column>
      <Column header="Porcentagem Margem Adiantamento">
        <template #body="{ data }">
          {{ data.porcentagemMargem5 ? data.porcentagemMargem5 : '-' }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>
<script>
import ParametroService from '@/service/ParametroService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
  },

  data() {
    return {
      matricula: {
        id: this.$route.params.id,
      },
      historico: [],
    }
  },

  created() {
    this.parametroService = new ParametroService(this.$http)
  },

  methods: {
    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.carregarHistorico()
    },

    onHide() {},

    carregarHistorico() {
      this.parametroService
        .getHistoricoByMatriculaId(this.matricula.id)
        .then((res) => {
          this.historico = res
        })
    },
  },
}
</script>
