<template>
  <Card>
    <template #content>
      <Toolbar class="mb-4">
        <template #start>
          <Button
            title="Adicionar"
            icon="pi pi-plus"
            class="mt-2 mr-2 p-button-success"
            @click="showFormParametro()" />
          <Button
            v-if="parametro.id"
            title="Histórico"
            icon="pi pi-list"
            class="mt-2 mr-2 p-button"
            @click="exibirHistorico()" />
        </template>
      </Toolbar>

      <Message v-if="parametro.id == null" :closable="false"
        >Nenhum parâmetro cadastrado.</Message
      >

      <div class="p-fluid formgrid grid">
        <div v-if="parametro.porcentagemMargem6" class="field col-6 md:col-6">
          <label for="porcentagemMargem6"
            >Porcentagem da Margem Empréstimo</label
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">%</span>
            <InputText
              id="porcentagemMargem6"
              v-model="parametro.porcentagemMargem6"
              disabled />
          </div>
        </div>
        <div v-if="parametro.porcentagemMargem4" class="field col-6 md:col-6">
          <label for="porcentagemMargem4">Porcentagem da Margem Cartão</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">%</span>
            <InputText
              id="porcentagemMargem4"
              v-model="parametro.porcentagemMargem4"
              disabled />
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div v-if="parametro.porcentagemMargem3" class="field col-6 md:col-6">
          <label for="porcentagemMargem3"
            >Porcentagem da Margem Bem Duráveis</label
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">%</span>
            <InputText
              id="porcentagemMargem3"
              v-model="parametro.porcentagemMargem3"
              disabled />
          </div>
        </div>
        <div v-if="parametro.porcentagemMargem5" class="field col-6 md:col-6">
          <label for="porcentagemMargem5"
            >Porcentagem da Margem Adiantamento</label
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">%</span>
            <InputText
              id="porcentagemMargem5"
              v-model="parametro.porcentagemMargem5"
              disabled />
          </div>
        </div>
        <div v-if="parametro.id != null" class="field col-6 md:col-6">
          <label for="cadastradoEm">Cadastrado Em</label>
          <InputText
            id="cadastradoEm"
            :value="parametro.auditMetadata.cadastradoEm | formatarData"
            disabled />
        </div>
        <div v-if="parametro.motivo" class="field col-12 md:col-12">
          <label for="motivo">Motivo</label>
          <Textarea
            id="motivo"
            v-model="parametro.motivo"
            :autoResize="true"
            disabled />
        </div>
      </div>

      <ParametroFormDialog
        :visible.sync="showParametroFormDialog"
        :propsparametro="parametro"
        :update="update"
        @onUpdate="carregarDados" />

      <HistoricoDialog :visible.sync="showHistoricoDialog" />
    </template>
  </Card>
</template>

<script>
import ParametroFormDialog from './ParametroFormDialog.vue'
import HistoricoDialog from './historicoDialog.vue'
import Parametro from '@/domain/Parametro'
import ParametroService from '@/service/ParametroService.js'

export default {
  name: 'ParametroServidor',

  components: {
    ParametroFormDialog,
    HistoricoDialog,
  },

  data() {
    return {
      parametro: new Parametro(),
      showParametroFormDialog: false,
      update: false,
      matricula: {
        id: this.$route.params.id,
      },
      showHistoricoDialog: false,
    }
  },

  created() {
    this.parametroService = new ParametroService(this.$http)
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.parametroService
        .getOneByMatriculaId(this.matricula.id)
        .then((res) => {
          this.parametro = res
        })
    },

    showFormParametro() {
      if (this.parametro.id) {
        this.update = true
      } else {
        this.parametro = {}
        this.update = false
      }
      this.showParametroFormDialog = true
    },

    exibirHistorico() {
      this.showHistoricoDialog = true
    },
  },
}
</script>
