<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block">
            <div class="inline-block col-6 md:col-6">
              <label for="porcentagemMargem6"
                >Porcentagem da Margem Empréstimo</label
              >
              <InputNumber
                id="porcentagemMargem6"
                v-model="parametro.porcentagemMargem6"
                suffix="%" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="porcentagemMargem4"
                >Porcentagem da Margem Cartão</label
              >
              <InputNumber
                id="porcentagemMargem4"
                v-model="parametro.porcentagemMargem4"
                suffix="%" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="porcentagemMargem3"
                >Porcentagem da Margem Bens Duráveis</label
              >
              <InputNumber
                id="porcentagemMargem3"
                v-model="parametro.porcentagemMargem3"
                suffix="%" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="porcentagemMargem5"
                >Porcentagem da Margem Adiantamento</label
              >
              <InputNumber
                id="porcentagemMargem5"
                v-model="parametro.porcentagemMargem5"
                suffix="%" />
            </div>
            <div class="inline-block col-12 md:col-12">
              <label for="motivo" class="required">Motivo</label>
              <Textarea
                id="motivo"
                v-model="parametro.motivo"
                :class="{
                  'p-invalid': submitted && v$.parametro.motivo.$invalid,
                }"
                :autoResize="true" />
              <div
                v-if="submitted && v$.parametro.motivo.required.$invalid"
                class="p-error">
                O campo motivo é obrigatório
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button
        :disabled="saving || semParametroPreenchido()"
        autofocus
        @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Parametro from '@/domain/Parametro.js'
import ParametroService from '@/service/ParametroService.js'
import { bus } from '@/main'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsparametro: {
      type: Object,
      required: false,
      default: new Parametro(),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      parametro: new Parametro(),
      saving: false,
      confirmDialog: false,
      submitted: false,
      matricula: {
        id: this.$route.params.id,
      },
    }
  },

  validations() {
    return {
      parametro: {
        motivo: {
          required,
        },
      },
    }
  },

  created() {
    this.service = new ParametroService(this.$http)
  },

  methods: {
    exibirHeader() {
      return 'Parâmetro'
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.parametro = JSON.parse(JSON.stringify(this.propsparametro))
    },

    onHide() {},

    validate() {
      this.submitted = true
      this.v$.parametro.$touch()

      if (this.v$.parametro.$invalid) {
        return
      } else {
        this.salvar()
      }
    },

    semParametroPreenchido() {
      return this.parametro.porcentagemMargem6 == null &&
        this.parametro.porcentagemMargem4 == null &&
        this.parametro.porcentagemMargem3 == null &&
        this.parametro.porcentagemMargem5 == null
        ? true
        : false
    },

    salvar() {
      this.saving = true
      if (this.parametro.matricula == null) {
        this.parametro.matricula = this.matricula
      }
      this.service
        .salvar(this.parametro)
        .then(() => {
          this.$emit('onUpdate')
          bus.$emit('carregarDadosServidor')
          if (this.parametro.id) {
            this.exibeToast('success', 'Parâmetro atualizado com sucesso')
          } else {
            this.exibeToast('success', 'Parâmetro cadastrado com sucesso')
          }
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
        .finally(() => {
          this.saving = false
          this.fechar()
        })
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}

.p-card {
  box-shadow: none;
}

.p-dialog .p-card .p-card-body {
  padding: 0rem !important;
}

.break-line {
  white-space: break-spaces;
}
</style>
